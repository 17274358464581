@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.scorecardNav {
  // margin: 0px -6px 0px -6px;
  margin-bottom: 12px;
  // background-color: var(--theme-color-light);
  // color: black;
  // [data-light-mode="false"] & {
  //   background-color: #2E2E2E;
  // }

  button {
    position: relative;
    z-index: 8;
    margin: 0 6px;
    padding: 8px 6px 6px 0;
    background: transparent;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    display: block;
    color: #888888;

    &[data-light-mode='false'] {
      color: var(--font-secondary);
    }

    @media (max-width: 767px) {
      padding: 4px 6px;
      &.active {
        font-weight: 700;
        background-color: var(--theme-color-light);
        color: white;
        border-radius: 20px;
        &:hover {
          color: white;
        }
      }

      &:hover {
        color: #888888;
      }
    }

    @media (min-width: 767px) {
      &.active {
        font-weight: 700;
        color: var(--theme-color-light);
      }

      &:hover {
        color: var(--theme-color-light);
      }
    }
  }
}

.sgmedia {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9e9e9e;
    font-size: 12px;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    padding: 0px 8px;
  }

  @media (max-width: 350px) {
    display: none;
  }
}

.sgMediaContainer {
  background-color: white;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: rgba(167,172,180,1);
    flex-grow: 1;
    opacity: 3;
  }
}

.scorecardSlider {
  padding: 16px 0;
  overflow: hidden;

  .item {
    cursor: pointer;
    width: 360px;
    height: 175px;
    padding: 15px 16px 0;
    background: var(--bkg-miniScoreCard);
    border: 1px solid var(--border-light);
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
    color: var(--font-color);

    > div:last-child p {
      margin-bottom: 0;
    }

    .liveImg {
      top: -15px;
      position: relative;
      width: 100%;
      height: 30px;
      margin-top: 5px;
    }

    .liveP {
      margin-right: 12px !important;
    }

    .detailsArrow {
      padding: 10px 8px;
      color: #454545;
      border-radius: 0.4em;
      position: relative;
      left: 10px;
      font-size: 12px;

      [data-light-mode='false'] & {
        color: var(--font-dark);
      }

      svg {
        margin-top: -1px;
        margin-left: 4px;
        color: #454545;

        [data-light-mode='false'] & {
          color: #d2d5d9;
        }
      }

      > div {
        width: 15px;
        position: relative;
        top: 12px;
        margin-left: -8px;
      }
    }

    .liveDot {
      height: 7px;
      width: 7px;
      background-color: #da3400;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      top: -0.5px;
      position: relative;
    }

    .notLive::after,
    .liveP::after,
    .nonLive::before {
      content: '';
      height: 4px;
      width: 4px;
      background-color: var(--font-light);
      border-radius: 50%;
      display: inline-block;
      top: -1.5px;
      left: 5px;
      position: relative;
    }

    .liveP::after {
      top: 16.5px;
    }

    .nonLive::before {
      left: -3px;
    }

    .notLive {
      margin-right: 12px;
      top: 10px;
    }

    .t10 {
      top: 10px;
    }

    .mtn20 {
      margin-top: -20px;
    }

    .nonLive {
      margin-left: 8px !important;
    }

    .batImg {
      width: 16px;
    }

    .batImg,
    .liveTeam {
      margin-left: 5px;
    }
  }

  .shortHeight {
    height: 145px;
  }

  p {
    margin: 0;
  }

  .scoreCardLink {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    background: transparent;
    text-indent: -2000px;
  }

  .head {
    color: $font-light;
    font-size: 11.5px;
    text-overflow: ellipsis;

    > * {
      display: inline;
    }
  }

  .switch label {
    color: var(--font-color);
    font-weight: 500;
  }

  .result {
    margin: 6px 0 12px;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .dangerTxt {
    color: var(--danger);
  }

  .teams {
    margin-bottom: 12px;
    font-weight: 800;
  }

  .team {
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 19px;
    position: relative;
  }

  .teamName {
    color: var(--font-color);
    font-weight: 800;

    span {
      margin-left: 6px;
    }

    > div {
      vertical-align: middle;
    }
  }

  .winner {
    width: 18px;
  }

  .btnList {
    margin: 0px -16px 0px -16px;
    background: var(--miniScoreCard-btn);
    padding: 3px 0;
    height: 100%;
    font-size: 14px;

    a {
      color: var(--font-dark);
      border-radius: 0.4em;

      &:hover {
        color: var(--theme-color-light);
      }
    }
  }

  .teamFlag {
    width: 20px;
    height: 20px;

    > span img {
      border-radius: 10px;
    }
  }
}

.containerStyles {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 440px) {
  .scorecardNav {
    margin: 0px -6px 8px;
  }

  .scorecardSlider {
    .slider {
      margin: 0px -10px;
    }

    .item {
      width: 300px;
    }
  }

  .containerStyles {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
