@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.slider {
  .inner {
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    >div {
      scroll-snap-align: start;
    }
  }

  .single>div {
    flex: 1 0 100%;
  }

  &.destroy {
    .inner {
      overflow-x: auto;
    }
  }
}

.arrow {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 30%;
  transform: translateY(-50%);
  font-size: 0;
  cursor: pointer;

  svg {
    // color: #ebe8e9;

    [data-light-mode="false"] & {
      color: #FFFFFF;
    }
  }

  &.prev {
    left: -3%;
  }

  &.next {
    right: -3%;
  }

  &.prev::before {
    @include transform(rotate(180deg));
  }

  // &:active { background: $theme-color; 
  //     &::before { @include filter(brightness(0) invert(1)); }
  // }
  &.disabled {
    display: none !important;
  }

  .navTransparent & {
    // background-color: var(--light-color-medium);
    border: none;

    &:hover::before {
      @include filter(none);
    }

    [data-light-mode=false] &::before {
      @include filter(brightness(0) invert(1));
    }
  }
}

.dots {
  button {
    margin: 0px 4px;
    width: 8px;
    height: 8px;
    font-size: 0;
    padding: 0;
    background: $font-light;
    position: relative;
    border: none;
    border-radius: 100%;

    // &::before { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: $theme-medium; opacity: 0; }
    &.active {
      background: $theme-medium;
    }
  }
}

.dotsPositionAbsolute {
  position: absolute;
  width: 95%;
  left: 8px;
  bottom: 18px;

  button {
    border-radius: 14px;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .slider {
    .inner {
      scroll-snap-type: x mandatory;

      >div {
        scroll-snap-align: start;

      }
    }
  }
}

@keyframes process {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}